<template>
  <div>
    <p class="p-b-5">
      <condition></condition>

      <Row :gutter="8">
        <i-col span="24">
          <div
            v-for="(item, index) in listContentCategory"
            :key="index"
            class="p-l-10 p-1-10 m-b-2"
            @click="changeChecked(item.serialNumber)"
            :class="
              checkContentCategorys.includes(item.serialNumber)
                ? 'workplatform-radio-button-active'
                : 'workplatform-radio-button'
            "
          >
            {{ item.moduleName }}
          </div>
          <Button
            type="success"
            size="small"
            class="m-l-5"
            @click="downLoadExecl()"
            >下载列表</Button
          >
        </i-col>
      </Row>

      <!-- <span class="m-l-50 title">
              PS：运维对账操作仅可对<span class="text-orange">已质检</span>和<span class="text-orange">未对账</span>的项进行操作！
            </span> -->
    </p>
    <Table
      :loading="loading"
      stripe
      :data="list"
      :columns="tableColumns"
      @on-selection-change="selectionChange"
      :height="clientHeight"
    ></Table>

    <Row>
      <i-col span="12">
        <span style="height: 28px; line-height: 28px"
          >已选中{{ feeitemIds.length }}条</span
        >
      </i-col>
      <i-col span="12">
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            @on-page-size-change="handlePageSizeChanged"
          ></Page>
        </div>
      </i-col>
    </Row>

    <Modal v-model="showDownLoadProgrammeModal">
      <div class="workplatform-title" slot="header">请选择下载类型</div>
      <Row>
        <i-col span="24">
          <RadioGroup v-model="downLoadProgramme" vertical>
            <Radio label="1">
              <span>全部下载</span>
            </Radio>
            <Radio label="2">
              <span>勾选下载</span>
            </Radio>
          </RadioGroup>
        </i-col>
      </Row>

      <div slot="footer">
        <Button type="text" @click="showDownLoadProgrammeModal = false"
          >取消</Button
        >
        <Button type="primary" @click="choiceDownLoadProgramme">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'
// import { formatReconcilliationStatus } from '@/utils/tagStatus'

import {
  getsettlementpagecontent,
  getPlanDetailPage,
  createsettlementdetail
} from '@/api/msp/settlement'
import condition from './maintenanceCondition'

export default {
  components: {
    condition
  },
  props: {
    params: Object
  },
  data () {
    return {
      clientHeight: 0,
      total: 0,
      loading: false,

      query: {
        pageNumber: 1,
        pageSize: 15,
        startDate: '',
        endDate: '',
        status: null,
        keyword: ''
      },
      list: [],
      tableColumns: [],

      feeitemIds: [], // 页面选中的所有项
      currentPageAllItemIds: [], // 当前操作的页面所有项的主键集合
      currentPageSelectionIdArray: [],
      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1',

      listContentCategory: [],
      checkContentCategorys: [1, 2, 3, 4]
    }
  },
  created () {
    this.feeitemIds = []
    this.clientHeight = document.documentElement.clientHeight - 340
    this.initsettlementpagecontent()
  },
  computed: {
    workOrderQuery () {
      return this.$store.state.workorderManageStore.workOrderQuery
    },
    beginUpdate () {
      return this.$store.state.workorderManageStore.beginUpdate
    }
  },
  watch: {
    beginUpdate (val) {
      this.feeitemIds = []

      this.query.supplierCompanyId =
        this.workOrderQuery.supplierCompanyId === '-1'
          ? null
          : this.workOrderQuery.supplierCompanyId // 供应商

      this.query.settlementCompanyId =
        this.workOrderQuery.settlementCompanyId === '-1'
          ? null
          : this.workOrderQuery.settlementCompanyId // 结算公司
      this.query.startDate = this.workOrderQuery.startDate // 开始时间
      this.query.endDate = this.workOrderQuery.endDate // 结束时间

      this.query.timeType = this.workOrderQuery.timeType // 时间类型

      this.query.keyword = this.workOrderQuery.keyword // 关键字
      this.query.minChargeAmount = this.workOrderQuery.minChargeAmount // 最小计费金额
      this.query.maxChargeAmount = this.workOrderQuery.maxChargeAmount // 最大计费金额

      this.query.statuses = this.workOrderQuery.statuses

      this.query.taskTypes = this.workOrderQuery.taskTypes // 作业类型

      this.query.resourceTypes = this.workOrderQuery.resourceTypes // 资源类型
      this.query.adTypes = this.workOrderQuery.adTypes // 用途

      this.reloadPage()
    }
  },
  methods: {
    initsettlementpagecontent () {
      const that = this
      getsettlementpagecontent({}).then((res) => {
        that.listContentCategory = res
        that.initColumns()
      })
    },

    // 变更多选框选择
    changeChecked (value) {
      const that = this
      if (that.checkContentCategorys.includes(value)) {
        const index1 = that.checkContentCategorys.indexOf(value)
        if (index1 > -1) {
          that.checkContentCategorys.splice(index1, 1)
        }
      } else {
        that.checkContentCategorys.push(value)
      }
      that.initColumns()
    },
    initColumns () {
      const that = this
      const tableColumnArray = {
        settlementCompanyName: {
          title: '结算方',
          key: 'settlementCompanyName',
          minWidth: 100,
          align: 'center'
        },
        supplierCompanyName: {
          title: '供应商',
          key: 'supplierCompanyName',
          minWidth: 100,
          align: 'center'
        },
        // billStatusName: {
        //   title: '结算状态',
        //   key: 'billStatusName',
        //   minWidth: 100,
        //   align: 'center',
        //   render: (h, data) => {
        //     return formatReconcilliationStatus(
        //       h,
        //       data.row.status,
        //       data.row.statusName)
        //   }
        // },

        resourceCode: {
          title: '资源编号',
          key: 'resourceCode',
          minWidth: 100,
          align: 'center'
        },
        skuName: {
          title: '单品名称',
          key: 'skuName',
          minWidth: 100,
          align: 'center'
        },
        assetName: {
          title: '线路',
          key: 'assetName',
          minWidth: 100,
          align: 'center'
        },
        stationName: {
          title: '站点',
          key: 'stationName',
          minWidth: 100,
          align: 'center'
        },
        positionName: {
          title: '位置',
          key: 'positionName',
          minWidth: 80,
          align: 'center'
        },
        resourceTypeName: {
          title: '资源类型',
          key: 'resourceTypeName',
          minWidth: 60,
          align: 'center'
        },

        taskTypeName: {
          title: '作业类型',
          key: 'taskTypeName',
          minWidth: 100,
          align: 'center'
        },
        statusName: {
          title: '状态',
          key: 'statusName',
          minWidth: 100,
          align: 'center'
        },
        quantityWithChargeStatus: {
          title: '质检/对账',
          key: 'quantityWithChargeStatus',
          minWidth: 100,
          align: 'center'
        },

        dispatchTime: {
          title: '派单日期',
          key: 'dispatchTime',
          minWidth: 100,
          align: 'center'
        },
        actionDate: {
          title: '作业日期',
          key: 'actionDate',
          minWidth: 80,
          align: 'center'
        },
        finishTime: {
          title: '完成时间',
          key: 'finishTime',
          minWidth: 60,
          align: 'center'
        },
        qualityTestTime: {
          title: '质检时间',
          key: 'qualityTestTime',
          minWidth: 60,
          align: 'center'
        },
        chargeTime: {
          title: '对账时间',
          key: 'chargeTime',
          minWidth: 60,
          align: 'center'
        },
        feeName: {
          title: '费用名称/材质',
          key: 'feeName',
          minWidth: 100,
          align: 'center'
          // render: (h, params) => {
          //   return h('div', [
          //     h(
          //       toMoney(params.row.materialUnitCost)
          //     )
          //   ])
          // }
        },
        materialUnitCost: {
          title: '单价',
          key: 'materialUnitCost',
          minWidth: 60,
          align: 'center'
        },
        materialChargeQuantity: {
          title: '数量',
          key: 'materialChargeQuantity',
          minWidth: 60,
          align: 'center'
        },
        amount: {
          title: '金额',
          key: 'amount',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },

        createCompanyName: {
          title: '代理商',
          key: 'createCompanyName',
          minWidth: 100,
          align: 'center'
        },
        sellerName: {
          title: '销售主体',
          key: 'sellerName',
          minWidth: 100,
          align: 'center'
        },
        contractCode: {
          title: '合同编号',
          key: 'contractCode',
          minWidth: 80,
          align: 'center'
        },
        contractName: {
          title: '合同名称',
          key: 'contractName',
          minWidth: 60,
          align: 'center'
        },
        adTypeName: {
          title: '用途',
          key: 'adTypeName',
          minWidth: 60,
          align: 'center'
        },
        schedule: {
          title: '档期',
          key: 'schedule',
          minWidth: 60,
          align: 'center'
        },
        advertiserName: {
          title: '客户名称',
          key: 'advertiserName',
          minWidth: 60,
          align: 'center'
        },
        brandName: {
          title: '品牌',
          key: 'brandName',
          minWidth: 60,
          align: 'center'
        },

        dispatcherCompanyName: {
          title: '派单主体',
          key: 'dispatcherCompanyName',
          minWidth: 60,
          align: 'center'
        },
        dispatchUserName: {
          title: '派单人',
          key: 'dispatchUserName',
          minWidth: 60,
          align: 'center'
        }
      }
      that.tableColumns = []
      // that.tableColumns = [tableColumnArray.selection]
      that.checkContentCategorys.forEach((item) => {
        const category = that.listContentCategory.filter(
          (x) => parseInt(x.serialNumber) === parseInt(item)
        )[0]
        if (category && category.rowNameList.length) {
          category.rowNameList.forEach((row) => {
            that.tableColumns.push(tableColumnArray[row.name])
          })
        }
      })

      if (!that.checkContentCategorys.length) {
        this.tableColumns = [
          {
            title: '资源编号',
            key: 'resourceCode',
            minWidth: 100,
            align: 'center'
          },
          {
            title: '工单金额',
            key: 'amount',
            minWidth: 60,
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'money'
                  },
                  toMoney(params.row.amount)
                )
              ])
            }
          }
        ]
      }
    },
    reloadPage () {
      this.query.pageNumber = 1
      this.loadPage()
    },
    loadPage () {
      this.loading = true
      const that = this
      getPlanDetailPage(that.query).then((res) => {
        if (res.list.length) {
          res.list.map((item) => {
            item.visible = item.visibleScope === 2
            item._checked = that.feeitemIds.includes(item.id)
            item.formatUnitCost = toMoney(item.unitCost)
            // item._disabled = (item.status === 2)
            return item
          })
          that.currentPageAllItemIds = res.list.map((item) => {
            return item.id
          })
        }

        that.list = res.list
        that.total = res.totalRow
        that.loading = false
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.loadPage()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.reloadPage()
    },
    selectionChange (selection) {
      const that = this
      var quantity = that.notAllowReconciliationQuantity
      const selectionIds = selection.map((item) => {
        return item.id
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach((item) => {
        // 列是否是选中状态
        const currentItem = that.list.filter((x) => x.id === item)[0]
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.feeitemIds.includes(item)) {
            that.feeitemIds.push(item)
            if (!currentItem.qualityTesting || currentItem.status === 2) {
              quantity = quantity + 1
            }
          }
        } else {
          // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.feeitemIds.includes(item)) {
            that.feeitemIds.splice(that.feeitemIds.indexOf(item), 1)
            if (!currentItem.qualityTesting || currentItem.status === 2) {
              quantity = quantity - 1
            }
          }
        }
      })
    },
    choiceDownLoadProgramme () {
      const that = this
      const params = {
        selectColumns: JSON.stringify(that.checkContentCategorys)
      }
      if (parseInt(that.downLoadProgramme) === 1) {
        params.supplierCompanyId =
          that.workOrderQuery.supplierCompanyId === '-1'
            ? null
            : that.workOrderQuery.supplierCompanyId
        params.settlementCompanyId =
          that.workOrderQuery.settlementCompanyId === '-1'
            ? null
            : that.workOrderQuery.settlementCompanyId
        params.startDate = that.workOrderQuery.startDate
        params.endDate = that.workOrderQuery.endDate
        params.timeType = this.workOrderQuery.timeType

        params.keyword = that.workOrderQuery.keyword
        params.minCost = that.workOrderQuery.minCost
        params.maxCost = that.workOrderQuery.maxCost

        params.taskTypes = that.workOrderQuery.taskTypes
        params.resourceTypes = that.workOrderQuery.resourceTypes

        params.adTypes = that.workOrderQuery.adTypes
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.feeItemIds = JSON.stringify(that.feeitemIds)
        }
      }

      that.downLoadExecl(params)
    },
    // 下载列表
    downLoadExecl () {
      const dateStr = ParseDate(new Date())
      // 全部下载
      // if (parseInt(this.downLoadProgramme) === 1) {
      //   if (params.startDate && params.endDate) {
      //     dateStr =
      //       ParseDateForMonth(params.startDate) +
      //       '至' +
      //       ParseDateForMonth(params.endDate)
      //   }
      // }
      const filename = '工单明细(' + dateStr + ').xlsx'
      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h('Icon', {
              class: 'spin-icon-load',
              props: {
                type: 'ios-loading',
                size: 24
              }
            }),
            h('div', '数据生成中...')
          ])
        }
      })
      const that = this
      const params = {
        selectColumns: JSON.stringify(that.checkContentCategorys)
      }
      params.supplierCompanyId =
        that.workOrderQuery.supplierCompanyId === '-1'
          ? null
          : that.workOrderQuery.supplierCompanyId
      params.settlementCompanyId =
        that.workOrderQuery.settlementCompanyId === '-1'
          ? null
          : that.workOrderQuery.settlementCompanyId
      params.startDate = that.workOrderQuery.startDate
      params.endDate = that.workOrderQuery.endDate
      params.timeType = this.workOrderQuery.timeType

      params.keyword = that.workOrderQuery.keyword
      params.minCost = that.workOrderQuery.minCost
      params.maxCost = that.workOrderQuery.maxCost

      params.taskTypes = that.workOrderQuery.taskTypes
      params.resourceTypes = that.workOrderQuery.resourceTypes

      params.adTypes = that.workOrderQuery.adTypes
      createsettlementdetail(params).then((res) => {
        this.pollingCreate(res.uniqueFlag, filename)
      })
    },
    pollingCreate (uniqueFlag, filename) {
      createsettlementdetail({ uniqueFlag: uniqueFlag }).then((res) => {
        if (res.status === 2) {
          this.$Spin.hide()
          this.pollingDownload(uniqueFlag, filename)
        } else {
          setTimeout(() => {
            this.pollingCreate(uniqueFlag, filename)
          }, 1500)
        }
      })
    },
    pollingDownload (uniqueFlag, filename) {
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/settlement/downloadsettlementdetail',
        { uniqueFlag: uniqueFlag },
        filename
      )
    }
  }
}
</script>
